import { ControllerParams, IHttpClient } from '@wix/yoshi-flow-editor';
import { makeAutoObservable } from 'mobx';
import { GiftCardProduct, VariantsOptions } from '../../../../types';
import {
  isInWixGiftCardsAppPopulation,
  queryGiftCardProducts,
} from '@wix/ambassador-gift-cards-v1-gift-card-product/http';
import { mapGiftCardProductV1ToGiftCardProduct } from '../utils/giftCardProductMapper';

interface GetProductResponse {
  gift_card_product: GiftCardProduct;
}

export class GiftCardService {
  httpClient: IHttpClient;
  instance: string;

  constructor(controllerParams: ControllerParams) {
    makeAutoObservable(this);
    this.httpClient = controllerParams.flowAPI.httpClient;
    this.instance = controllerParams.controllerConfig.appParams.instance;
  }

  async shouldUseNewGiftCardServices() {
    try {
      const {
        data: { shouldUseNewGiftCardServices },
      } = await this.httpClient.request(isInWixGiftCardsAppPopulation({}));
      return shouldUseNewGiftCardServices ?? false;
    } catch (error) {
      console.error(
        'An error occurred while checking if new Gift Card services should be used:',
        error,
      );
    }
  }

  async getProduct({
    currency,
  }: {
    currency?: string | null;
  }): Promise<GiftCardProduct> {
    const requestUrl = 'https://wix.rise.ai/catalog/templates';
    const requestUrlWithCurrency = `${requestUrl}?currency=${currency}`;

    const {
      data: { gift_card_product },
    } = await this.httpClient.request<GetProductResponse>({
      url: 'https://www.wix.com/_serverless/rise-proxy/rise',
      method: 'post',
      headers: {
        Authorization: this.instance,
      },
      disableWixHeaders: true,
      data: {
        riseRequest: {
          url: currency ? requestUrlWithCurrency : requestUrl,
          method: 'get',
          data: {},
        },
      },
    });

    // inctead of script : for existing stores
    const variantsWithType = gift_card_product.variants.map(
      (variant: VariantsOptions) => ({
        ...variant,
        type: variant.type || 'preset',
        active: variant.active === undefined ? true : variant.active,
      }),
    ) as VariantsOptions[];

    return { ...gift_card_product, variants: variantsWithType };
  }

  async getGiftCardProductV1() {
    try {
      const {
        data: { giftCardProducts },
      } = await this.httpClient.request(queryGiftCardProducts({}));
      const product = giftCardProducts?.[0];
      return (
        product && mapGiftCardProductV1ToGiftCardProduct(this.instance, product)
      );
    } catch (error) {
      console.error(
        'An error occurred while fetching Gift Card product:',
        error,
      );
    }
  }

  async getCurrencyCode(): Promise<string> {
    const getCurrencyCodeRequest = await this.httpClient.request<string>({
      url: 'https://www.wix.com/_serverless/rise-proxy/wix',
      method: 'post',
      headers: {
        Authorization: this.instance,
      },
      disableWixHeaders: true,
      data: {
        wixRequest: {
          serviceRequest: {
            entity: 'SettingsReaderService',
            method: 'getCurrency',
            params: {},
          },
        },
      },
    });

    return getCurrencyCodeRequest.data;
  }
}
