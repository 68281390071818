import { Checkout } from '@wix/ambassador-checkout/types';
import {
  GiftCardCatalogOptions,
  GiftCardProduct,
  IPurchaseOptions,
  GiftCardProductVariant,
} from '../../../../types';
import { AppServices } from '../components/GiftCard/controller/initializeServices';
import { makeAutoObservable } from 'mobx';

interface IPurchaseOptionsValidation {
  recipientEmail: boolean;
  quantity: boolean;
  customAmount: boolean;
}

const RISE_APP_ID = 'd80111c5-a0f4-47a8-b63a-65b54d774a27';
export class GiftCardCheckoutStore {
  purchase_options_validation: IPurchaseOptionsValidation = {
    recipientEmail: false,
    quantity: true,
    customAmount: false,
  };
  show_errors: boolean = false;
  creatingCheckout: boolean = false;
  addingToCart: boolean = false;
  createdCheckout?: Checkout;

  constructor(private readonly services: AppServices) {
    makeAutoObservable(this);
  }

  setPurchaseOptionsValidation = (
    key: keyof IPurchaseOptionsValidation,
    value: boolean,
  ) => {
    this.purchase_options_validation[key] = value;
  };

  checkValidation = (keys: (keyof IPurchaseOptionsValidation)[]) => {
    return !keys.some((key) => !this.purchase_options_validation[key]);
  };

  isValidPurchaseOptions = (purchase_options: IPurchaseOptions): Boolean => {
    const keysToValidate: (keyof IPurchaseOptionsValidation)[] = ['quantity'];
    if (purchase_options.isGift) {
      keysToValidate.push('recipientEmail');
    }
    if (purchase_options.variantId === 'custom') {
      keysToValidate.push('customAmount');
    }
    return this.checkValidation(keysToValidate);
  };

  splitFullName = (fullName: string): { first: string; last: string } => {
    const [first, ...last] = fullName.split(' ').filter(Boolean);
    return {
      first,
      last: last.join(' '),
    };
  };

  buildGiftCardCatalogOptions = (
    purchase_options: IPurchaseOptions,
    currencyCode: string,
  ): GiftCardCatalogOptions => {
    const {
      variantId,
      recipientEmail,
      recipientName,
      deliverAt,
      greetingMessage,
      quantity,
      isGift,
      customAmount,
    } = purchase_options;

    const checkoutPurchaseOptions: GiftCardCatalogOptions = {
      quantity,
      currency: currencyCode,
    };

    if (variantId === 'custom') {
      checkoutPurchaseOptions.customAmount = customAmount;
    } else {
      checkoutPurchaseOptions.variantId = variantId;
    }

    if (isGift) {
      checkoutPurchaseOptions.giftingInfo = {
        recipientInfo: {
          firstName: this.splitFullName(recipientName).first,
          lastName: this.splitFullName(recipientName).last,
          email: recipientEmail,
        },
        greetingMessage,
      };

      if (deliverAt) {
        checkoutPurchaseOptions.giftingInfo.deliverAt = deliverAt;
      }
    }

    return checkoutPurchaseOptions;
  };

  addToCurrentCart = async (
    product: GiftCardProduct,
    purchase_options: IPurchaseOptions,
    currencyCode: string,
    postAction: string,
  ) => {
    this.show_errors = true;
    const isValid = this.isValidPurchaseOptions(purchase_options);
    if (!isValid) {
      return;
    }

    this.addingToCart = true;

    const addToCartRequest = {
      customLineItems: [],
      lineItems: [
        {
          quantity: purchase_options.quantity,
          couponScopes: [],
          descriptionLines: [],
          catalogReference: {
            appId: RISE_APP_ID,
            catalogItemId: product.product_id,
            options: this.buildGiftCardCatalogOptions(
              purchase_options,
              currencyCode,
            ),
          },
        },
      ],
    };
    await this.services.cartService.addToCart(addToCartRequest);
    await this.postAddToCartNavigation(postAction);
  };

  postAddToCartNavigation = async (postAction: string) => {
    await this.services.cartService.postAddToCartNavigation(postAction);
    this.addingToCart = false;
  };

  createCheckout = async (
    productId: GiftCardProduct['product_id'],
    purchase_options: IPurchaseOptions,
    currencyCode: string,
  ) => {
    try {
      this.show_errors = true;
      const isValid = this.isValidPurchaseOptions(purchase_options);
      if (!isValid) {
        return;
      }

      this.creatingCheckout = true;
      const checkoutPurchaseOptions = this.buildGiftCardCatalogOptions(
        purchase_options,
        currencyCode,
      );
      const shouldUseNewGiftCardServices =
        await this.services.giftCardService.shouldUseNewGiftCardServices();
      this.createdCheckout =
        await this.services.giftCardCheckoutService.createGiftCardCheckout(
          productId,
          checkoutPurchaseOptions,
          shouldUseNewGiftCardServices,
        );
    } catch (error) {}
  };

  reportCheckout = async ({
    product,
    purchase_options,
    currencyCode,
    type,
  }: {
    product: GiftCardProduct;
    purchase_options: IPurchaseOptions;
    currencyCode: string;
    type: string;
  }) => {
    const presetVariants = product?.variants.filter(
      (variant) => variant.type === 'preset',
    ) as GiftCardProductVariant[];

    const selectedVariant = presetVariants.find(
      (variant) => variant.id === purchase_options.variantId,
    );

    if (selectedVariant) {
      this.services.giftCardCheckoutService.reportCheckout({
        productId: product.product_id,
        checkoutId: this.createdCheckout?.id,
        purchase_options,
        price: selectedVariant.price,
        currencyCode,
        type,
      });
    }
  };

  navigateToCheckout = async (checkoutId: string) => {
    await this.services.giftCardCheckoutService.navigateToCheckout(checkoutId);
  };

  toProps() {
    return {
      purchase_options_validation: this.purchase_options_validation,
      setPurchaseOptionsValidation: this.setPurchaseOptionsValidation,
      show_errors: this.show_errors,
      creatingCheckout: this.creatingCheckout,
      addingToCart: this.addingToCart,
      checkout: this.createdCheckout,
      createCheckout: this.createCheckout,
      reportCheckout: this.reportCheckout,
      navigateToCheckout: this.navigateToCheckout,
      addToCart: this.addToCurrentCart,
      postAddToCartNavigation: this.postAddToCartNavigation,
    };
  }
}
