import { ILocation } from '@wix/yoshi-flow-editor';
import {
  CustomVariant,
  GiftCardProduct,
  GiftCardProductVariant,
} from '../../../../types';
import { AppServices } from '../components/GiftCard/controller/initializeServices';
import { makeAutoObservable, toJS } from 'mobx';

export class GiftCardStore {
  loadingProduct: Boolean = false;
  product?: GiftCardProduct;
  customVariant?: CustomVariant;
  presetVariants: GiftCardProductVariant[] = [];
  readonly locale?: string;
  readonly siteCurrency?: string;
  currencyCode?: string;
  customVariantDisplay?: string;
  readonly location: ILocation;

  constructor(private readonly services: AppServices) {
    makeAutoObservable(this);

    const { wixCodeApi } = services.controllerParams.controllerConfig;
    this.locale = wixCodeApi.site.regionalSettings;
    this.siteCurrency = wixCodeApi.site.currency;
    this.location =
      services.controllerParams.controllerConfig.wixCodeApi.location;
  }

  setCustomVariantDisplay = (foramt: string) => {
    this.customVariantDisplay = foramt;
  };

  init = async () => {
    try {
      const currencyQueryParam = this.location.query.currency;
      this.product =
        (await this.services.giftCardService.shouldUseNewGiftCardServices())
          ? await this.services.giftCardService.getGiftCardProductV1()
          : await this.services.giftCardService.getProduct({
              currency: currencyQueryParam,
            });

      this.customVariant = this.product?.variants.find(
        (variant) => variant.type === 'custom' && variant.active,
      ) as CustomVariant;

      this.presetVariants = this.product?.variants.filter(
        (variant) => variant.type === 'preset' && variant.active,
      ) as GiftCardProductVariant[];

      this.location.onChange((a: any) => {
        this.init();
      });

      this.currencyCode =
        currencyQueryParam ||
        this.siteCurrency ||
        (await this.services.giftCardService.getCurrencyCode());

      this.loadingProduct = false;
    } catch (error) {}
  };

  toProps() {
    return {
      init: this.init,
      loadingProduct: this.loadingProduct,
      product: this.product,
      locale: this.locale,
      currencyCode: this.currencyCode,
      customVariantDisplay: this.customVariantDisplay,
      setCustomVariantDisplay: this.setCustomVariantDisplay,
      customVariant: this.customVariant,
      presetVariants: this.presetVariants,
    };
  }
}
